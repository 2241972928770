import React from "react";

import $ from "jquery"

  
const CardPromociones = ({data,id}) => {
 console.log(data)
    setTimeout(() => {
    
  
        $(".masInfo_toggle"+data.idpromo).unbind('click').click(function()
       {
         console.log('clic')
         $(".masInfo"+data.idpromo).slideToggle( "slow");
         if($(".masInfo_toggle"+data.idpromo).text().includes("Ocultar información")){
          $(".masInfo_toggle"+data.idpromo).text('Más información')
          $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaAbajo.svg'/>")
          console.log('clic ocultar')
        }else{
    
        
        console.log('clic')
        $(".masInfo_toggle"+data.idpromo).text('Ocultar información')
        $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaArribaVermas.svg'/>")
        console.log('clic mas')

        }
          /* if ($("#author_bio_wrap_toggle").text() == "Expand Author Details")
           {			
             $("#author_bio_wrap_toggle").html("Hide Author Details")
           }
           else 
           {		
             $("#author_bio_wrap_toggle").text("Expand Author Details")
           }
          
         */
       }); 


       $(".masInfo_toggle"+data.idpromo).mouseenter(function () {
        // $(".author_bio_wrap_toggle"+data.roomTypeID).css('color','#FF6C52')
         //$(".author_bio_wrap_toggle"+data.roomTypeID).css('background','unset')
         //$(".author_bio_wrap_toggle"+data.roomTypeID).css('-webkit-text-fill-color','unset')
         //console.log($(".author_bio_wrap_toggle"+data.roomTypeID).text())
         //$(".author_bio_wrap_toggle"+data.roomTypeID).text('')
         if($(".masInfo_toggle"+data.idpromo).text().includes("Ocultar información")){

           $(".masInfo_toggle"+data.idpromo).text('')
   
         $(".masInfo_toggle"+data.idpromo).text('Ocultar información')
             $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaArribaHover.svg'/>")
   
           //$(".flecha"+data.roomTypeID).attr('src',"../../static/flechaArribaHover.png")
     console.log('ocultar hover')
         }else{
           $(".masInfo_toggle"+data.idpromo).text('')
   
     
         
         //console.log('clic')
         $(".masInfo_toggle"+data.idpromo).text('Más información')
         $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaAbajoHover.svg'/>")
         //$(".flecha"+data.roomTypeID).attr('src',"../../static/flechaAbajoHover.png")
         console.log('masInfo hover')

         }
         console.log('enter')
       })
   
       $(".masInfo_toggle"+data.idpromo).mouseleave(function () {
         // $(".author_bio_wrap_toggle"+data.roomTypeID).css('color','#FF6C52')
          //$(".author_bio_wrap_toggle"+data.roomTypeID).css('background','unset')
          //$(".author_bio_wrap_toggle"+data.roomTypeID).css('-webkit-text-fill-color','unset')
    
          //console.log($(".author_bio_wrap_toggle"+data.roomTypeID).text())
   
          if($(".masInfo_toggle"+data.idpromo).text().includes("Ocultar información")){
           $(".masInfo_toggle"+data.idpromo).text('')
  
           $(".masInfo_toggle"+data.idpromo).text('Ocultar información')
           $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaArribaVermas.svg'/>")
          // $(".flecha"+data.roomTypeID).attr('src',"../../static/flechaArribaVermas.png")
          console.log('afuera ocultar hover')

         }else{
     
         
         console.log('clic')
         $(".masInfo_toggle"+data.idpromo).text('')
   
        $(".masInfo_toggle"+data.idpromo).text('Más información')
         $(".masInfo_toggle"+data.idpromo).append("                <img class='ms-3' style='width:24px;height:12px;align-self:center;' src='../../static/flechaAbajo.svg'/>")
         //$(".flecha"+data.roomTypeID).attr('src',"../../static/flechaAbajo.png")
         console.log('afuera ocultar 2 hover')

         }
        })
     
      
     }, 2000);
  return (
   <div className={'block bloqueDispo  mb-3  mt-1 mb-5'} >
<div className={'row mx-1'}>
    <div className={'  col-md-5 col-sm-12 p-0'} style={{position:'relative'}}>
        <img style={{borderRadius: '20px', width: '100%',height: '240px',objectFit:'cover'}} src={data.imagen}/>
    </div>
<div className={'col-md-7 col-sm-12 ps-4 text-start'}>
  <div className="heigPromo">
  <h1 className="mt-0 mb-2 tituloPromo">{data.titulo}</h1>
    <p className="labelNormal">{data.detalle}</p>
    <h3 className="vigencia">Vigencia</h3>
    <div className="mb-3 labelNormal" dangerouslySetInnerHTML={{__html: data.validez}}  />
  </div>
    
    <a id={'author_bio_wrap_toggle'} className={'d-flex justify-content-center justify-content-md-start links masInfo_toggle'+data.idpromo}>Mas Información               
      <img className={"ms-3 flecha"+data.idpromo} style={{width:'24px',height:'12px',alignSelf:'center'}} src="../../static/flechaAbajo.svg"/>
</a>

</div>
<div id={'author_bio_wrap'} className={'masInfo'+data.idpromo+' text-start '} style={{display:'none'}}>
        <div className="mt-3">
        <div className="letraNormal" dangerouslySetInnerHTML={{__html: data.descripcion}}  />

        </div>
    </div>

</div>
  
</div>
  );
};



export default CardPromociones;